import { useEffect, useRef, useState } from 'react'

import useMediaQuery from '@equifax-ui/hooks/useMediaQuery'

import { useConditionalRendering } from '@/hooks/useConditionalRendering'
import { usePlans } from '@/hooks/usePlans'

export const usePlansSection = () => {
  const [expandedPlans, setExpandedPlans] = useState({})
  const [visibleElementIndex, setVisibleElementIndex] = useState(2)
  const [isPaymentDetailsOpen, setIsPaymentDetailsOpen] = useState(false)

  const listRef = useRef()
  const itemsRef = useRef([])

  const { renderedContentType } = useConditionalRendering()
  const { postpaidPlans } = usePlans()

  const isMobile = useMediaQuery('screen and (max-width: 1024px)')

  const plansLength =
    renderedContentType === 'default'
      ? postpaidPlans.length + 1
      : postpaidPlans.length

  useEffect(() => {
    if (renderedContentType && postpaidPlans.length) {
      const firstVisibleCardIndex = isMobile ? 2 : 1
      const nextVisibleCard = itemsRef.current[firstVisibleCardIndex]

      scrollList(nextVisibleCard)
      setVisibleElementIndex(firstVisibleCardIndex)
    }
  }, [isMobile, postpaidPlans, renderedContentType])

  const scrollList = (nextVisibleCard, direction) => {
    const {
      top = 0,
      x = 0,
      width = 0
    } = nextVisibleCard?.getBoundingClientRect() || {}

    const left = direction === 'left' ? -x : x

    const desktopScrollSize =
      direction === 'left'
        ? -((width + width / 2) / 2)
        : (width + width / 2) / 2

    listRef.current.scrollBy({
      top,
      left: isMobile ? left : desktopScrollSize,
      behavior: 'smooth'
    })
  }

  const isOnScreen = (element) => {
    const { x } = element.getBoundingClientRect()
    const { width } = listRef.current.getBoundingClientRect()

    const minScrollSize = isMobile ? 0 : 80
    const maxScrollSize = width / 3

    return x > minScrollSize && x < maxScrollSize
  }

  const onScroll = () => {
    const newVisibleCardIndex =
      itemsRef.current.findIndex((card) => isOnScreen(card)) ?? 0
    if (
      newVisibleCardIndex >= 0 &&
      newVisibleCardIndex !== visibleElementIndex
    ) {
      setVisibleElementIndex(newVisibleCardIndex)
    }
  }

  const onClickController = (direction) => {
    const nextVisibleCard = itemsRef.current[visibleElementIndex]

    scrollList(nextVisibleCard, direction)
  }

  return {
    listRef,
    itemsRef,
    plansLength,
    postpaidPlans,
    expandedPlans,
    visibleElementIndex,
    isMobile,
    isPaymentDetailsOpen,
    renderedContentType,
    setExpandedPlans,
    setIsPaymentDetailsOpen,
    onScroll,
    onClickController
  }
}
